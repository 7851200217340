import { useContext } from "react";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import AppContext from "../../contexts/app.context";
import SearchBar from "./SearchBar";

const Navbar = () => {
  const { setTopic } = useContext(AppContext);
  const handleClick = () => {
    setTopic("Home");
    window.location.pathname = "/";
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      p={2}
      sx={{
        background: "#edb818",
        top: 0,
        justifyContent: "space-between",
        zIndex: 100
      }}
    >
      <Link
        style={{ display: "flex", alignItems: "center" }}
        onClick={handleClick}
      >
        <img src={"/mm_quiz.png"} alt="logo" height={45} />
      </Link>
      <SearchBar />
    </Stack>
  );
};

export default Navbar;

import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { Typography, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import QCard from "../../components/qcard/QCard";
import TopicsBar from "../../components/topics-bar/TopicsBar";
import AppContext from "../../contexts/app.context";
import QCardSkeleton from "../../components/qcard/QCardSkeleton";

const Feed = () => {
  const [loading, setLoading] = useState(true);
  const { appState, setQuizzes, setTopic } = useContext(AppContext);

  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    if(searchParams.get("u")) {
      if (localStorage.getItem("USER_ID") !== searchParams.get("u")) {
        localStorage.setItem("USER_ID", searchParams.get("u"));
      }
    }
  }, [searchParams]);

  const fetchQuizzes = async () => {
    setLoading(true);
    let url =
      appState.topic === "Home"
        ? `https://api.mm-quiz.com/quizzes/home?timestamp=${Date.now()}`
        : `https://api.mm-quiz.com/quizzes/topic?topic=${
            appState.topic
          }&timestamp=${Date.now()}`;
    try {
      const response = await axios.get(url);
      setQuizzes(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  window.onbeforeunload = () => {
    setTopic("Home");
  };

  useEffect(() => {
    if (window.performance.getEntries()[0].type === "reload") {
      setTopic("Home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchQuizzes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.topic]);

  return (
    <div>
      <div
        style={{
          maxWidth: "100vw",
          marginTop: "15px",
          marginBottom: "25px",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <TopicsBar />
      </div>
      <div>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"start"}
          gap={3}
          sx={{ justifyContent: { xs: "center" } }}
        >
          {loading
            ? Array.from({ length: 32 }, (_, i) => <QCardSkeleton key={i} />)
            : appState.quizzes.map((quiz, index) => (
                <QCard quiz={quiz} key={index} />
              ))}
        </Stack>
        <a href="./Privacy_Policy.pdf" target="_blank">
          <Typography variant="subtitle1" sx={{ mt: "40px", mb: "40px", color: "#d1a51f", textAlign: 'center' }} fontFamily={"'Glacial Indifference', sans-serif"}>
          {'Mobile Media Labs FZ-LLC | Privacy Policy'}
          </Typography>
        </a>
      </div>
    </div>
  );
};

export default Feed;

import { Card, CardContent, Skeleton } from "@mui/material";

const QCardSmallSkeleton = () => {
  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: 300,
        backgroundColor: "#22303C",
        color: "white",
        borderRadius: "15px",
        marginBottom: "20px",
      }}
    >
      <Skeleton variant="rectangular" width={300} height={180} />
      <CardContent>
        <Skeleton variant="text" sx={{ fontSize: "4rem" }} width={200} />
      </CardContent>
    </Card>
  );
};

export default QCardSmallSkeleton;

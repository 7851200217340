import React, { useState, memo, useEffect } from "react";

import AppContext from "./app.context";

// useEffect(() => {
//   AsyncStorage.getItem("appState").then((asValue) => {
//     if (asValue) {
//       const state = JSON.parse(asValue);

//       setAppState(state);
//     } else {
//       setAppState((value) => ({ ...value, id: "0" }));
//     }
//   });
//   eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

// useEffect(() => {
//   AsyncStorage.setItem("appState", JSON.stringify(appState));
// }, [appState]);

const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    id: null,
    created: 0,
    email: "",
    quiz: {},
    homePage: [],
    quizzes: [],
    relatedQuizzes: [],
    topic: "Home",
  });

  useEffect(() => {
    if (localStorage.getItem("appState") !== null) {
      const state = JSON.parse(localStorage.getItem("appState"));

      setAppState(state);
    } else {
      setAppState((value) => ({ ...value, id: "0" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("appState", JSON.stringify(appState));
  }, [appState]);

  const createUser = async (user) => {
    setUserId(user.id);
    setUserCreated(user.created);
  };

  const setUserId = (userId) => {
    setAppState((value) => ({ ...value, id: userId }));
  };

  const setUserCreated = (created) => {
    setAppState((value) => ({ ...value, created: created }));
  };

  const setUserEmail = async (email) => {
    setAppState((value) => ({ ...value, email: email }));
  };

  const setQuiz = async (quiz) => {
    setAppState((value) => ({ ...value, quiz: quiz }));
  };

  const setQuizzes = async (quizzes) => {
    setAppState((value) => ({ ...value, quizzes: quizzes }));
  };

  const setRelatedQuizzes = async (relatedQuizzes) => {
    setAppState((value) => ({ ...value, relatedQuizzes: relatedQuizzes }));
  };

  const setHomePage = async (videos) => {
    setAppState((value) => ({ ...value, homePage: videos }));
  };

  const setTopic = async (topic) => {
    setAppState((value) => ({ ...value, topic: topic }));
  };

  const value = {
    appState,
    createUser,
    setUserId,
    setUserEmail,
    setQuiz,
    setQuizzes,
    setRelatedQuizzes,
    setHomePage,
    setTopic,
  };

  return <AppContext.Provider {...{ value }}>{children}</AppContext.Provider>;
};

export default memo(AppProvider);

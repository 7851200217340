import { useContext, useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import AppContext from "../../contexts/app.context";

const QCard = ({ quiz }) => {
  const { setQuiz } = useContext(AppContext);
  const navigation = useNavigate();
  const {
    TITLE,
    SHORT_DESCRIPTION,
    IMAGE,
    TOPIC,
    SLUG,
    QUESTIONS,
    DIFFICULTY,
  } = quiz;
  const { IMAGE_URLS_SMALL, IMAGE_ALT } = JSON.parse(IMAGE);
  const { JPG, WEBP } = IMAGE_URLS_SMALL;

  const returnDifficulty = (DIFFICULTY) => {
    if (DIFFICULTY === 1) {
      return "Easy";
    } else if (DIFFICULTY === 2 || DIFFICULTY === 3) {
      return "Medium";
    } else if (DIFFICULTY === 4) {
      return "Hard";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToQuiz = () => {
    window.scrollTo(0, 0);
    setQuiz(quiz);
    navigation(`/${TOPIC}/${SLUG}`);
    document.dispatchEvent(new CustomEvent("quiz"));
  };

  const handleMiddleClick = (event) => {
    if (event.button === 1) {
      window.open(`/${TOPIC}/${SLUG}`, "_blank", "noopener,noreferrer");
    }
  };

  const TruncatedText = ({ text, maxLines }) => {
    const style = {
      display: '-webkit-box',
      WebkitLineClamp: maxLines,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    };

    return <div style={style}>{text}</div>;
  };

  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: { xs: "290px", sm: "358px", md: "320px" },
        backgroundColor: "#22303C",
        color: "white",
        borderRadius: "15px",
        ":hover": {
          backgroundColor: "#33495c",
          transform: "scale(1.01)",
          transition: "all 0.3s ease",
          cursor: "pointer",
        },
      }}
    >
      <CardMedia
        component="img"
        alt={IMAGE_ALT}
        height="180"
        image={WEBP || JPG}
        onClick={navigateToQuiz}
        onMouseDown={handleMiddleClick}
      />
      <CardContent onClick={navigateToQuiz} onMouseDown={handleMiddleClick}>
        <Typography
          sx={{
            position: "absolute",
            top: "149px",
            right: "0px",
            zIndex: 99,
            backgroundColor: "#333333CC",
            padding: "5px 10px",
            borderTopLeftRadius: "5px",
            fontFamily: "'Glacial Indifference', sans-serif",
            fontSize: 14,
            fontWeight: "bold"
          }}
        >
          {TOPIC.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
        </Typography>
        <Typography variant="subtitle1" fontSize={18} align="center" fontWeight="bold" color="#fff" fontFamily={"'Glacial Indifference', sans-serif"} component="div">
          <TruncatedText text={TITLE} maxLines={1} />
        </Typography>
        <Typography variant="subtitle2" fontSize={14}  align="center" color="lightgrey" fontFamily={"'Glacial Indifference', sans-serif"} component="div">
          <TruncatedText text={SHORT_DESCRIPTION} maxLines={1} />
        </Typography>
        <Typography variant="subtitle2" fontSize={14}  align="center" color="lightgrey" fontFamily={"'Glacial Indifference', sans-serif"} component="div">
          <TruncatedText text={JSON.parse(QUESTIONS).length + ' ' + returnDifficulty(DIFFICULTY) + ' Questions'} maxLines={1} />
        </Typography>
      </CardContent>
      <CardActions sx={{ mt: "-15px", mb: "10px", mx: "auto" }}>
        <Button onClick={navigateToQuiz} variant="contained" style={{backgroundColor: "#d1a51f", fontFamily: "'Glacial Indifference', sans-serif"}}>
          Take Quiz
        </Button>
      </CardActions>
    </Card>
  );
};

export default QCard;

import { Card, CardActions, CardContent, Skeleton } from "@mui/material";

const QCardSkeleton = () => {
  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: { xs: "290px", sm: "358px", md: "320px" },
        backgroundColor: "#22303C",
        color: "white",
        borderRadius: "15px",
        height: "335px",
        ":hover": {
          backgroundColor: "#33495c",
          transform: "scale(1.01)",
          transition: "all 0.3s ease",
          cursor: "pointer",
        },
      }}
    >
      <Skeleton variant="rectangular" width={345} height={200} />
      <CardContent>
        <Skeleton variant="text" sx={{ fontSize: "2.5rem" }} width={250} />
        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={200} />
      </CardContent>
      <CardActions disableSpacing sx={{ mt: "auto", mb: "10px" }}>
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
      </CardActions>
    </Card>
  );
};

export default QCardSkeleton;

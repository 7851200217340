import axios from "axios";
import { useState, useEffect } from "react";
import {
  Alert,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const QuizComponent = ({ QUESTIONS, SLUG, IMAGE }) => {
  const [loading, setLoading] = useState(false);
  const [displayInsights, setDisplayInsights] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [renderAnswers, setRenderAnswers] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [quizFinished, setQuizFinished] = useState(false);
  const [userScore, setUserScore] = useState(0);

  useEffect(() => {
    function handleInsightsStatusChange() {
      if (localStorage.getItem('insights') !== null && localStorage.getItem('insights') !== '0') {
        if (Date.now() - parseInt(localStorage.getItem('insights'), 10) > 5000) {
          setDisplayInsights(true);
        }
      } else {
        setDisplayInsights(false);
      }
    }
    handleInsightsStatusChange();

    const insightsTimeout = setInterval(() => {
      handleInsightsStatusChange();
    }, 1500);
    return () => {
      clearTimeout(insightsTimeout);
    };
  }, []);

  const nextQuestionHandler = () => {
    if (
      currentQuestionIndex < QUESTIONS.length - 1 &&
      answeredQuestions[currentQuestionIndex]?.answer
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const previousQuestionHandler = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const answerQuestionHandler = (e) => {
    let currAnswer = e.target.value;
    let currQuestion = {
      id: QUESTIONS[currentQuestionIndex].id,
      answer: currAnswer,
    };
    let tempRenderAnswers = [...renderAnswers];
    tempRenderAnswers[currentQuestionIndex] = currAnswer;
    let newAnsweredQuestions = [...answeredQuestions];
    newAnsweredQuestions[currentQuestionIndex] = currQuestion;
    setAnsweredQuestions(newAnsweredQuestions);
    setRenderAnswers(tempRenderAnswers);
  };

  const submitQuizHandler = async () => {
    let body = {
      SLUG,
      ANSWERS: answeredQuestions,
    };

    if (currentQuestionIndex === QUESTIONS.length - 1) {
      setLoading(true);
      try {
        let response = await axios.post(
          "https://api.mm-quiz.com/quizzes/answers?timestamp=" +
            Date.now(),
          JSON.stringify(body)
        );

        if (response.data.SCORE >= 0 && response.data.SLUG === SLUG) {
          setUserScore(response.data.SCORE);
          setQuizFinished(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const generateFinishedQuizMessage = (userScore) => {
    document.dispatchEvent(new CustomEvent("sum"));
    if (userScore === QUESTIONS.length) {
      return `Wow! You just got a perfect score with ${userScore} out of ${QUESTIONS.length}! Go ahead and play one of the other quizzes.`;
    }
    if (userScore / QUESTIONS.length >= 0.8) {
      return `Great job! You were really close! You got ${userScore} out of ${QUESTIONS.length}! Reload to try again to get the perfect score, or go ahead and play one of the other quizzes.`;
    }
    if (userScore / QUESTIONS.length >= 0.5) {
      return `Not bad! You got ${userScore} out of ${QUESTIONS.length}! Reload to try again to get the perfect score, or go ahead and play one of the other quizzes.`;
    }
    if (userScore / QUESTIONS.length >= 0.2) {
      return `Oh no! You got ${userScore} out of ${QUESTIONS.length}! Reload to try again to get the perfect score, or go ahead and play one of the other quizzes.`;
    }
    return `Yikes! You got ${userScore} out of ${QUESTIONS.length}! Reload to try again to get the perfect score, or go ahead and play one of the other quizzes.`;
  };

  const generateHint = () => {
    if(localStorage.getItem("USER_ID") && localStorage.getItem("USER_ID") !== null) {
      return window.atob('SGludDogV2F0Y2ggbW9yZSB2aWRlb3Mgd2hpbGUgeW91IGRvIHRoZSBuZXh0IHF1aXouIEVhY2ggdmlkZW8gbXVsdGlwbGllcyB5b3VyIHNjb3JlIQ==');
    }
    return '';
  }

  return (
    <Card
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: `linear-gradient( rgba(11, 11, 11, 0.7) 100%, rgba(0, 0, 0, 0.7)100%),url(${IMAGE})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        color: 'white',
        borderRadius: '15px',
        minHeight: '45vh'
      }}
    >
    {displayInsights ? (
      <CardContent><Alert style={{ marginBottom: "35px" }} severity="error">
        The video gives you important insights into the current quiz.
      </Alert></CardContent>) : (
      <CardContent>
        {!quizFinished ? (
          <FormControl>
            <FormLabel
              sx={{
                color: "white",
                mb: "10px",
                fontSize: "1.1rem",
                fontWeight: "bold",
                "&.Mui-focused": { color: "white" },
              }}
              id="answers-radio-button-group"
            >
              {QUESTIONS[currentQuestionIndex].id}:{" "}
              {QUESTIONS[currentQuestionIndex].questionText}
            </FormLabel>
            <RadioGroup
              aria-labelledby="answers-radio-button-group"
              name="radio-buttons-group"
              value={renderAnswers[currentQuestionIndex] || ""}
              onChange={answerQuestionHandler}
            >
              {QUESTIONS[currentQuestionIndex].answerOptions.map((answer) => (
                <div key={answer.answerText}>
                  <FormControlLabel
                    value={answer.answerText}
                    control={<Radio sx={{color: "#FC0", '&.Mui-checked': {color: "#FC0"}}} />}
                    label={answer.answerText}
                    checked={
                      QUESTIONS[currentQuestionIndex]?.answer &&
                      answer.answerText ===
                        QUESTIONS[currentQuestionIndex]?.answer
                    }
                  />
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        ) : (
          <>
            <Typography
              sx={{
                color: "white",
                mb: "10px",
                fontSize: "1.1rem",
                fontWeight: "bold",
                "&.Mui-focused": { color: "white" },
              }}
              id="answers-radio-button-group"
              fontFamily={"'Glacial Indifference', sans-serif"}
            >
              {generateFinishedQuizMessage(userScore)}
            </Typography>
            <Typography
              sx={{
                color: "white",
                mb: "10px",
                fontSize: "1.1rem",
                fontWeight: "bold",
                "&.Mui-focused": { color: "white" },
              }}
              id="answers-radio-button-group"
              fontFamily={"'Glacial Indifference', sans-serif"}
            >
              {generateHint()}
            </Typography>
          </>
        )}
      </CardContent>)}
      {displayInsights ? (<div></div>) : (<CardActions disableSpacing sx={{ mt: "auto" }}>
        <div>
          <Button
            onClick={previousQuestionHandler}
            variant="contained"
            size="large"
            sx={{ mr: "10px" }}
            disabled={quizFinished || currentQuestionIndex === 0}
            sx={{
              backgroundColor: "#d1a51f",
              color: "white",
              marginRight: 1,
              '&:hover': {
                backgroundColor: "#edba21",
                color: "white"
              }
            }}
          >
            <ArrowBackIcon />
          </Button>
          <Button
            onClick={nextQuestionHandler}
            variant="contained"
            size="large"
            disabled={
              quizFinished ||
              currentQuestionIndex === answeredQuestions.length ||
              currentQuestionIndex + 1 === QUESTIONS.length
            }
            sx={{backgroundColor: "#d1a51f", color: "white", '&:hover': {backgroundColor: "#edba21", color: "white"}}}
          >
            <ArrowForwardIcon />
          </Button>
        </div>
        <LoadingButton
          onClick={submitQuizHandler}
          variant="contained"
          size="large"
          sx={{ ml: "auto" }}
          loading={loading}
          disabled={
            quizFinished ||
            currentQuestionIndex + 1 !== QUESTIONS.length ||
            currentQuestionIndex === answeredQuestions.length
          }
          color="success"
        >
          Submit
        </LoadingButton>
      </CardActions>)}
    </Card>
  );
};

export default QuizComponent;

import MusicNoteIcon from "@mui/icons-material/MusicNote";
import TerrainIcon from "@mui/icons-material/Terrain";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DevicesIcon from "@mui/icons-material/Devices";
import QuotesIcon from "@mui/icons-material/FormatQuote";
import SportsVolleyIcon from "@mui/icons-material/SportsVolleyball";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PublicIcon from "@mui/icons-material/Public";
import ScienceIcon from "@mui/icons-material/Science";
import PaletteIcon from "@mui/icons-material/Palette";
import PetsIcon from "@mui/icons-material/Pets";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import ChurchIcon from "@mui/icons-material/Church";
import BoltIcon from "@mui/icons-material/Bolt";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Face2Icon from "@mui/icons-material/Face2";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ForestIcon from "@mui/icons-material/Forest";
import DiamondIcon from "@mui/icons-material/Diamond";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import LightbulbCircleIcon from "@mui/icons-material/LightbulbCircle";
import HomeIcon from "@mui/icons-material/Home";

export const topics = [
  { name: "Home", icon: <HomeIcon /> },
  { name: "Landmarks", icon: <TerrainIcon /> },
  { name: "Literature", icon: <MenuBookIcon /> },
  { name: "Quotes", icon: <QuotesIcon /> },
  { name: "Music", icon: <MusicNoteIcon /> },
  { name: "Sports", icon: <SportsVolleyIcon /> },
  { name: "History", icon: <LibraryBooksIcon /> },
  { name: "Geography", icon: <PublicIcon /> },
  { name: "Technology", icon: <DevicesIcon /> },
  { name: "Science", icon: <ScienceIcon /> },
  { name: "Art", icon: <PaletteIcon /> },
  { name: "Animals", icon: <PetsIcon /> },
  { name: "Food", icon: <FastfoodIcon /> },
  { name: "Mythology", icon: <BoltIcon /> },
  { name: "Space", icon: <RocketLaunchIcon /> },
  { name: "Capitals", icon: <LocationCityIcon /> },
  { name: "Celebrities", icon: <Face2Icon /> },
  { name: "Civilizations", icon: <HolidayVillageIcon /> },
  { name: "Games", icon: <SportsEsportsIcon /> },
  { name: "Environment", icon: <ForestIcon /> },
  { name: "Fashion", icon: <DiamondIcon /> },
  { name: "Health", icon: <HealthAndSafetyIcon /> },
  { name: "Religions", icon: <ChurchIcon /> },
  { name: "Inventions", icon: <LightbulbCircleIcon /> },
];

export const listeners = {
  sumSwitch: false,
  quizSwitch: false
};
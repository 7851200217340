import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { listeners } from "../../utils/constants";
import { useSearchParams } from "react-router-dom";

const SearchBar = () => {
  const [sum, setSum] = useState("-1");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function fetchData(userId) {
      const result = await fetch("https://analytics.mm-quiz.com/dragbehind?userId=" + userId + "&date=" + Date.now());
      var tSum = new Intl.NumberFormat(navigator.language).format(await result.text());
      setSum(tSum);
      localStorage.setItem("sum", tSum);
    }

    if(localStorage.getItem("sum") && localStorage.getItem("sum") !== null) {
      setSum(localStorage.getItem("sum"));
    } else {
      if(searchParams.get("u")) {
        fetchData(searchParams.get("u"));
      } else if(localStorage.getItem("USER_ID") && localStorage.getItem("USER_ID") !== null) {
        fetchData(localStorage.getItem("USER_ID"));
      } 
    }
  }, []);

  if (!listeners.sumSwitch) {
    listeners.sumSwitch = true;
    document.addEventListener("sum", async (e) => {
      if(localStorage.getItem("USER_ID") && localStorage.getItem("USER_ID") !== null) {
        const result = await fetch("https://analytics.mm-quiz.com/dragbelow?userId=" + localStorage.getItem("USER_ID") + "&date=" + Date.now());
        var tSum = new Intl.NumberFormat(navigator.language).format(await result.text());
        setSum(tSum);
        localStorage.setItem("sum", tSum);
      }
    });
  }

  if(localStorage.getItem("USER_ID") && localStorage.getItem("USER_ID") !== null && sum !== "-1") {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', textAlign: 'right', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: 10, paddingLeft: 10, paddingRight: 10 }}>
          <img src={"/sum.png"} alt="Sum" height={20} />
          <Typography variant="h4" fontWeight="bold" color="#dcc572" fontFamily={"'Glacial Indifference', sans-serif"} fontSize={25} style={{marginLeft: 5}}>
            {sum}
          </Typography>
        </div>
      </div>
    );
  }
};

export default SearchBar;

import { createContext } from "react";

const AppContext = createContext({
  appState: {
    id: null,
    created: 0,
    email: "",
    quiz: {},
    homePage: [],
    quizzes: [],
    relatedQuizzes: [],
    topic: "Home",
  },
});

export default AppContext;

import React, { useContext } from "react";
import { Stack } from "@mui/material";

import "./TopicsBar.css";
import { topics } from "../../utils/constants";
import AppContext from "../../contexts/app.context";

const Categories = () => {
  const { appState, setTopic } = useContext(AppContext);

  return (
    <Stack
      direction="row"
      sx={{
        maxWidth: "100vw",
        overflowX: "scroll",
        height: { sx: "auto", md: "10%" },
        alignItems: "flex-start",
        justifyContent: "flex-start",
        position: "relative",
        marginX: { sx: "10px", md: "100px" },
      }}
    >
      {topics.map((topic, index) => (
        <button
          className="topic-btn"
          onClick={() => setTopic(topic.name)}
          style={{
            background: topic.name === appState.topic && "#d1a51f",
            color: "white",
            marginLeft: index === 0 ? 15 : 0,
          }}
          key={topic.name}
        >
          <span
            style={{
              color: topic.name === appState.topic ? "white" : "#d1a51f",
              marginRight: "10px",
            }}
          >
            {topic.icon}
          </span>
          <span
            style={{
              opacity: topic.name === appState.topic ? "1" : "0.8",
            }}
          >
            {topic.name}
          </span>
        </button>
      ))}
    </Stack>
  );
};

export default Categories;

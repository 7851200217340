import { Stack, Skeleton } from "@mui/material";

const QuizSkeletonComponent = () => {
  return (
    <div style={{ width: "100vw", marginTop: "50px" }}>
      <Stack direction={"row"}>
        <div style={{ width: "85%", marginLeft: "100px" }}>
          <Skeleton
            variant="text"
            sx={{
              fontSize: "10rem",
            }}
          />
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={"40%"} />
          <div style={{ width: "100%", position: "relative" }}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={500}
              sx={{ borderRadius: "15px", marginBottom: "20px" }}
            />
          </div>
          <Skeleton
            variant="rectangular"
            width={200}
            height={50}
            sx={{ mb: "20px", borderRadius: "15px", marginX: "auto" }}
          />

          <Skeleton variant="text" sx={{ fontSize: "4rem" }} width={"80%"} />
          <Skeleton variant="rectangular" width={"100%"} height={500} />
        </div>
      </Stack>
    </div>
  );
};

export default QuizSkeletonComponent;

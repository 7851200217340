import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import Navbar from "./components/navbar/Navbar";
import Feed from "./pages/feed/Feed";
import QuizPage from "./pages/quiz/QuizPage";
import AppProvider from "./contexts/app.provider";

const App = () => (
  <AppProvider>
    <div
      style={{
        backgroundColor: "#222337",
        minHeight: "100vh",
      }}
    >
      <BrowserRouter>
        <Box>
          <Navbar
            sx={{
              zIndex: 100,
            }}
          />
          <Routes>
            <Route path="/" exact element={<Feed />} />
            <Route path="/landmarks/:slug" exact element={<QuizPage />} />
            <Route path="/literature/:slug" exact element={<QuizPage />} />
            <Route path="/quotes/:slug" exact element={<QuizPage />} />
            <Route path="/music/:slug" exact element={<QuizPage />} />
            <Route path="/sports/:slug" exact element={<QuizPage />} />
            <Route path="/history/:slug" exact element={<QuizPage />} />
            <Route path="/geography/:slug" exact element={<QuizPage />} />
            <Route path="/technology/:slug" exact element={<QuizPage />} />
            <Route path="/science/:slug" exact element={<QuizPage />} />
            <Route path="/art/:slug" exact element={<QuizPage />} />
            <Route path="/animals/:slug" exact element={<QuizPage />} />
            <Route path="/food/:slug" exact element={<QuizPage />} />
            <Route path="/mythology/:slug" exact element={<QuizPage />} />
            <Route path="/space/:slug" exact element={<QuizPage />} />
            <Route path="/capitals/:slug" exact element={<QuizPage />} />
            <Route path="/celebrities/:slug" exact element={<QuizPage />} />
            <Route path="/civilizations/:slug" exact element={<QuizPage />} />
            <Route path="/games/:slug" exact element={<QuizPage />} />
            <Route path="/environment/:slug" exact element={<QuizPage />} />
            <Route path="/fashion/:slug" exact element={<QuizPage />} />
            <Route path="/health/:slug" exact element={<QuizPage />} />
            <Route path="/religions/:slug" exact element={<QuizPage />} />
            <Route path="/inventions/:slug" exact element={<QuizPage />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </div>
  </AppProvider>
);

export default App;
